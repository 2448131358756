<template>

    <b-row>
        
        <b-col cols="12" md="8" lg="8">
            <b-card no-body>
                <b-card-header>
                    <b-card-title>
                        Добавить спец. предложение 
                    </b-card-title>
                </b-card-header>
                
                <b-card-body>
                    <validation-observer ref="form">
                        <b-form>
                            
                            <b-form-group class="form-group-with-label">
                                <validation-provider #default="{ errors }" name="user" rules="required" >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Тип предложения</span>  
                                    
                                    <v-select
                                      :options="types"
                                      v-model="form.type"
                                      label="name"
                                      :clearable="false"
                                      placeholder="Выбрать"
                                    >
                                        <template slot="no-options">
                                            Нет результатов поиcка
                                        </template>
                                    </v-select>     
                                    
                                </validation-provider>
                            </b-form-group> 
                            
                            <template v-if="form.type && form.type.value === 1">
                            
                                <b-row>

                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Заголовок предложения</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.title"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Заголовок предложения (en)</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.title_en"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12" md="6" lg="6">
                                       <b-form-group class="form-group-with-label">

                                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                                <div style="flex-grow: 1;">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        rules=""
                                                    >
                                                        <span :class="{
                                                            'text-muted' : errors.length > 0 ? false : true,
                                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                                            'text-danger' : errors.length > 0 ? true : false
                                                        }" style="font-size: 12px;">Мощность без скидки</span>    
                                                        <b-form-input
                                                            type="number"
                                                            placeholder="Мощность контракта без скидки"
                                                            v-model.number="form.old_ths"
                                                        />
                                                    </validation-provider>
                                                </div>
                                                <div class="mt-auto mb-auto">
                                                    <span class="text-muted">TH/s</span>
                                                </div>
                                            </div>

                                            <vue-slider
                                                v-model="form.old_ths"
                                                :min="10"
                                                :max="500"
                                                :dotSize="16"
                                                :height="6"
                                                :contained="false"
                                                :interval="1"
                                                :duration="0.2"
                                                tooltip="none"
                                            />

                                        </b-form-group>

                                        <div class="d-flex justify-content-between mb-1">
                                            <span class="text-muted" style="font-size: 12px;">10 TH/s</span>
                                            <span class="text-muted" style="font-size: 12px;">500 TH/s</span>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6" lg="6">
                                         <b-form-group class="form-group-with-label">
                                            <validation-provider rules="" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Скидка</span>                             
                                                <b-form-input
                                                    id="discount_percent"
                                                    type="number"
                                                    v-model.number="form.discount_percent"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">

                                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                                <div style="flex-grow: 1;">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        rules="required"
                                                    >
                                                        <span :class="{
                                                            'text-muted' : errors.length > 0 ? false : true,
                                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                                            'text-danger' : errors.length > 0 ? true : false
                                                        }" style="font-size: 12px;">Мощность</span>    
                                                        <b-form-input
                                                            type="number"
                                                            placeholder="Мощность контракта"
                                                            v-model.number="form.ths"
                                                        />
                                                    </validation-provider>
                                                </div>
                                                <div class="mt-auto mb-auto">
                                                    <span class="text-muted">TH/s</span>
                                                </div>
                                            </div>

                                            <vue-slider
                                                v-model="form.ths"
                                                :min="10"
                                                :max="500"
                                                :dotSize="16"
                                                :height="6"
                                                :contained="false"
                                                :interval="1"
                                                :duration="0.2"
                                                tooltip="none"
                                            />

                                        </b-form-group>

                                        <div class="d-flex justify-content-between mb-1">
                                            <span class="text-muted" style="font-size: 12px;">10 TH/s</span>
                                            <span class="text-muted" style="font-size: 12px;">500 TH/s</span>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">

                                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                                <div style="flex-grow: 1;">
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        name="period"
                                                        rules="required|integer"
                                                    >
                                                        <span :class="{
                                                            'text-muted' : errors.length > 0 ? false : true,
                                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                                            'text-danger' : errors.length > 0 ? true : false
                                                        }" style="font-size: 12px;">Период</span>

                                                        <b-form-input
                                                            type="number"
                                                            placeholder="Срок действия контракта"
                                                            v-model.number="form.period"
                                                            :min="12"
                                                            :max="24"
                                                            :formatter="periodFormatter"

                                                        />
                                                    </validation-provider>
                                                </div>
                                                <div class="mt-auto mb-auto">
                                                    <span class="text-muted">мес.</span>
                                                </div>
                                            </div>

                                            <vue-slider
                                                v-model="form.period"
                                                :min="12"
                                                :max="36"
                                                :dotSize="16"
                                                :height="6"
                                                :contained="false"
                                                :interval="1"
                                                :duration="0.2"
                                                tooltip="none"
                                            />
                                        </b-form-group>

                                        <div class="d-flex justify-content-between mb-1">
                                            <span class="text-muted" style="font-size: 12px;">12 месяцев</span>
                                            <span class="text-muted" style="font-size: 12px;">36 месяцев</span>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Текст акцента</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.caption"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Текст акцента (en)</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.caption_en"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Текст действия</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.action"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Текст действия (en)</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.action_en"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                 <b-row>
                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Стоимость без скидки</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model.number="form.old_price_amount"
                                                  type="number"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Стоимость</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model.number="form.price_amount"
                                                  type="number"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                
                            </template>
                            
                            <template v-if="form.type && form.type.value === 2">
                                 <b-row>
                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider #default="{ errors }" name="user" rules="required" >
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Майнер</span>  

                                                <v-select
                                                  :options="miners"
                                                  v-model="form.miner"
                                                  label="name"
                                                  @search="fetchMiners"
                                                  :clearable="false"
                                                  placeholder="Поиск майнера"
                                                >
                                                    <template slot="no-options">
                                                        Нет результатов поиcка
                                                    </template>
                                                </v-select>     

                                            </validation-provider>
                                        </b-form-group> 
                                    </b-col>
                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Текст действия</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.action"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="4" lg="4">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Текст действия (en)</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model="form.action_en"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="required" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Стоимость</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model.number="form.price_amount"
                                                  type="number"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6" lg="6">
                                        
                                    </b-col>
                                </b-row>

                                 <b-row>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Стоимость без скидки</span>                             
                                                <b-form-input
                                                  id="name"
                                                  v-model.number="form.old_price_amount"
                                                  type="number"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6" lg="6">
                                        <b-form-group class="form-group-with-label">
                                            <validation-provider rules="" #default="{ errors }">
                                                <span :class="{
                                                    'text-muted' : errors.length > 0 ? false : true,
                                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                                    'text-danger' : errors.length > 0 ? true : false
                                                }" style="font-size: 12px;">Скидка</span>                             
                                                <b-form-input
                                                    id="discount_percent"
                                                    type="number"
                                                    v-model.number="form.discount_percent"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </template>
                            
                            <div class="mt-1 d-flex justify-content-end">
                                <b-button @click="submit" variant="primary">Сохранить</b-button>
                            </div>
                            
                            
                        </b-form>
                    </validation-observer>
                </b-card-body>
                
            </b-card>
        </b-col>
        
        <b-col cols="12" md="4" lg="4">
            <b-card no-body>
                <b-card-header>
                    <b-card-title>
                        Предпросмотр
                    </b-card-title>
                </b-card-header>
                <template v-if="form.type">
                    <offer-card-preview v-if="form.type.value === 1" :item="preview" :isPreview="true" />    
                    <template v-else >
                        <offer-card-preview v-if="form.miner" :item="preview" :isPreview="true" />  
                        <b-card-body v-else>
                            <b-card-text class="text-muted text-center">
                                Для предпросмотра карточки предложения необходимо выбрать майнер.
                            </b-card-text>
                        </b-card-body>
                    </template>
                </template>
                <b-card-body v-else>
                    <b-card-text class="text-muted text-center">
                        Для предпросмотра карточки предложения необходимо выбрать тип предложения.
                    </b-card-text>
                </b-card-body>
                    
            </b-card>
        </b-col>
        
    </b-row>
    
</template>

<script>
    
    import vSelect from 'vue-select'
    import VueSlider from 'vue-slider-component'
    import OfferCardPreview from "@/components/OfferCard"
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, integer } from '@validations'
    import flatPickr from 'vue-flatpickr-component'

    export default {

        data() {
            return {
                required,
                integer,
                types: [
                    { name: "Аренда мощности", value: 1 },
                    { name: "Покупка майнера", value: 2 }
                ],
                form: {
                    action: "Подключить контракт",
                    caption: "5 дней бесплатно",
                    action_en: "Connect contract",
                    caption_en: "5 days free",
                    period: 14,
                    price_amount: 1200,
                    ths: 15,
                    title: "Старт",
                    title_en: "Start",
                    type: null,
                    until: null,
                    old_ths: null,
                    discount_percent: null,
                    old_price_amount: null,
                    miner: null,
                    special_offer: -1
                },
                miners: []
            }
        },
        props: {
            special_offer: Number,
            until: Number
        },
        methods: {
            
            submit( args ) {
                
                this.$refs.form.validate().then( success => {
                    if( success ) {
                       this.$request.post("contracts/createOffer", {
                           form: this.params
                       }).then( rsp => {
                           this.$router.push({
                               name: 'admin-contracts-offers'
                           });
                       });
                    }
                });
            },
            
            fetchMiners( query, loading ) {
                this.$request.get("miners/search", {
                    q: query
                }).then( rsp => {
                    this.miners = rsp.items;
                });
            },
            
            periodFormatter( args ) {
                
                let val = parseInt( args );
                
                if( val < 12 ) {
                    val = 12;
                } else if( val > 36 ) {
                    val = 36;
                } 
                
                return val;
                
            },
        },
        components: {
            vSelect,
            VueSlider,
            OfferCardPreview,
            flatPickr,
            ValidationProvider,
            ValidationObserver
        },
        computed: {
            
            params() {
                
                if( this.form.type.value === 1 ) {
                    return  {
                        action: this.form.action,
                        caption: this.form.caption,
                        action_en: this.form.action_en,
                        caption_en: this.form.caption_en,
                        period: this.form.period,
                        price_amount: this.form.price_amount,
                        ths: this.form.ths,
                        title: this.form.title,
                        title_en: this.form.title_en,
                        type: this.form.type ? this.form.type.value : null,
                        until: this.form.until,
                        old_ths: this.form.old_ths,
                        discount_percent: this.form.discount_percent,
                        old_price_amount: this.form.old_price_amount,
                        special_offer: this.form.special_offer
                    }
                } else {
                    return  {
                        action: this.form.action,
                        action_en: this.form.action_en,
                        price_amount: this.form.price_amount,
                        type: this.form.type ? this.form.type.value : null,
                        miner: this.form.miner ? this.form.miner.id : null ,
                        until: this.form.until,
                        discount_percent: this.form.discount_percent,
                        old_price_amount: this.form.old_price_amount,
                        special_offer: this.form.special_offer
                    }
                }
                
                
                
            },
            
            preview() {
                return  {
                    action: this.form.action,
                    caption: this.form.caption,
                    period: this.form.period,
                    price_amount: this.form.price_amount,
                    ths: this.form.ths,
                    title: this.form.title,
                    type: this.form.type ? this.form.type.value : null,
                    miner: this.form.miner,
                    until: null,
                    old_ths: this.form.old_ths,
                    discount_percent: this.form.discount_percent,
                    old_price_amount: this.form.old_price_amount
                }
            },
        },
        watch: {
            "form.type" : function( type ) {
                switch( type.value ) {
                    case 1: this.form.action = "Подключить контракт"; this.form.action_en = "Connect contract"; break;
                    case 2: this.form.action = "Купить"; this.form.action_en = "Buy"; break;
                }
            },
        },
        mounted() {

            if( !this.special_offer || !this.until ) {
                return this.$router.push({
                    name: 'admin-contracts-offers'
                });
            }
          
            this.form.special_offer = this.special_offer
            this.form.until = this.until
        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-slider.scss';
</style>